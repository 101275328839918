import React from "react";

import Header from "./components/header";
import Footer from "./components/footer";
import { slug } from "./components/slider";

const Services = () => {
  return (
    <>
      <Header />
      <main className="bg-gray-200 py-8 space-y-8">
        <div className="mx-auto  max-w-6xl container space-y-4">
          <div className="p-8 bg-white font-playfirDisplay italic text-2xl">
            Услуги
          </div>
          <div className="p-8 bg-white">
            <div className="grid lg:grid-cols-3 gap-4 text-slate-600">
              {slug.map((slug) => {
                return (
                  <div className="bg-white hover:bg-slate-100" key={slug.title}>
                    <a href={`/${slug.link}`}>
                      <div className="lg:h-48">
                        <img
                          src={slug.img}
                          title={slug.title}
                          alt={slug.title}
                          className="lg:w-full lg:h-full w-screen"
                        />
                      </div>
                      <div className="p-4 text-center font-semibold">
                        {slug.title}
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Services;
